import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`15:00 emom:`}</p>
    <p>{`Minute 1:00- 8 Shoulder Press \\@60% 1rm`}</p>
    <p>{`Minute 2:00- 10 Legless Wall Balls (20/14`}{`#`}{`, 10/9’)`}</p>
    <p>{`Minute 3:00- 12 DB Pullovers (estimated 60%)`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 amrap:`}</p>
    <p>{`10-Ring Dips (Rx+ 5-Ring Muscle Ups)`}</p>
    <p>{`10-D Ball Slams (30/20`}{`#`}{`)(rx+ 40/30`}{`#`}{`)`}</p>
    <p>{`15-Burpees`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      